function NewlineText(props) {
  const text = props.text;
  const newText = text.split('\n').map(str => <p>{str}</p>);
  
  return newText;
}

function ListFromArray(props) {
  const arr = props.arr
  return (
    <ul 
      style = {{
        listStyleType: "circle",
      }}
    >
      {arr.map(str => <li style={{marginLeft: "32px"}}>{str}</li>)}
    </ul>
  )
}


export const  Bio = (props) => {
  const link = <a href="https://abramsclaghorn.com/jaya-kanoria/" target="_blank">Abrams Claghorn Gallery</a>
  return (
    <div id='bio'
      style={{
        width: "100vw",
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/Jaya Artist/Pic for website.jpg`}
        alt="Profile"
        style={{
          width: "100vw",
          maxWidth: "800px",
          maxHeight: "600px",
          objectFit: "contain",
          paddingTop: "20px",
          paddingLeft: "20px",
          paddingBottom: "20px",
          display: "block",
        }}
      />
      <div
        style = {{
          marginLeft: "auto",
          marginRight: "auto",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingBottom: "20px",
          textAlign: "left",
          width: "100vw",
        }}
      >
        <p
          style = {{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >Bio</p>
        <NewlineText text={props.data ? props.data.Bio : "Loading... "}/>
        <p
          style = {{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >Current</p>
        <p>Work on display and stocked at {link}, Solano Avenue Albany (near Berkeley), California, USA since 2017.</p>
        <p
          style = {{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >Exhibitions</p>
        <p
          style = {{
            fontSize: "16px",
            fontWeight: "bold",
            textIndent: "16px",
          }}
        >Solo Shows</p>
        <div>
          {
            props.data ? 
            <ListFromArray arr={props.data.Exhibitions["Solo Shows"]}/> : 
            <p>"Loading..."</p>
          }
        </div>
        <p
          style = {{
            fontSize: "16px",
            fontWeight: "bold",
            textIndent: "16px",
          }}
        >Selected Group Shows</p>
        <div>
          {
            props.data ? 
            <ListFromArray arr={props.data.Exhibitions["Selected Group Shows"]}/> : 
            <p>"Loading..."</p>
          }
        </div>
        <p
          style = {{
            fontSize: "20px",
            fontWeight: "bold",
          }}
        >Statement</p>
        <NewlineText text={props.data ? props.data.Statement : "Loading... "}/>
      </div>
    </div>
  )
}
