import { Carousel } from './carousel';
import CatalogData from "../data/catalog.json";

export const Portfolio = () => {

  const captionStyle = {
    fontSize: "1em",
    // fontWeight: "bold",
  };
  const slideNumberStyle = {
    fontSize: "20px",
    color: "lightgrey",
    // fontWeight: "bold",
  };

  return (
    <div id="portfolio" className="portfolio">
      <div style={{ textAlign: "center" }}>
        <div
          style={{
            padding: "100px 20px",
          }}
        >
          <Carousel
            data={CatalogData}
            time={10000}
            width="850px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            slideNumber={true}
            slideNumberStyle={slideNumberStyle}
            captionPosition="top"
            automatic={true}
            dots={false}
            pauseIconColor="darkgrey"
            pauseIconSize="100px"
            slideBackgroundColor="white"
            slideImageFit="contain"
            thumbnails={true}
            thumbnailWidth="100px"
            showNavBtn={true}
            style={{
              textAlign: "center",
              maxWidth: "850px",
              margin: "40px auto",
            }}
          />
        </div>
      </div>
    </div>
  );
};
